<template>
  <div>
    <div class="pa-2 cyan">
      <span class="text-subtitle-1 font-weight-bold white--text"
        >全媒体共通の設定</span
      >
    </div>
    <v-expansion-panels flat tile>
      <v-expansion-panel class="item-card-backcolor">
        <v-expansion-panel-header class="pa-1 pl-4 pr-4 text-left">
          <span style="text-decoration: underline">使い方を見る</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ul>
            <li>
              キャストの画像を設定します。ここで指定した画像は、すべての媒体に反映されます。<br /><span
                class="red--text"
                >※媒体個別の設定を行っている場合、そちらが優先されます</span
              >
            </li>
            <li>
              画像は共通で20枚まで設定できますが、更新できる枚数は各媒体の上限に従います。
            </li>
            <li>
              矢印アイコン<v-icon dense>mdi-upload</v-icon
              >をクリックして画像をアップロードします。
            </li>
            <li>
              ヤトイテにアップロードできる画像形式は、JPEG・PNG・GIF・WEBPです。ただし、更新先の媒体に対応している形式でアップしてください。<br /><span
                class="red--text"
                >※更新先の媒体に対応していない画像形式でアップした場合、更新に失敗する可能性があります。</span
              >
            </li>
            <li>
              スイッチが有効(<v-icon color="info" dense
                >mdi-toggle-switch</v-icon
              >)の場合に更新します。無効(<v-icon dense
                >mdi-toggle-switch-off</v-icon
              >)の場合は更新しません。クリックで有効と無効を切り替えできます。
            </li>
            <li>
              ゴミ箱<v-icon dense>mdi-delete</v-icon
              >をクリックすると画像を削除します。
            </li>
            <li>
              画像の右上にある四角いアイコン<v-icon dense>mdi-drag</v-icon
              >をドラッグして画像を並び替えます。
            </li>
            <li>保存ボタンを押すまで、設定は上書きされません。</li>
          </ul>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card
      tile
      elevation="0"
      class="item-card pa-2 pt-4 pb-8 item-card-backcolor"
      style="max-width: 100%"
    >
      <v-card-text class="pa-0 pb-2">
        <image-selector
          ref="refImageCommonComponent"
          :img_num="setting.cast_image_number"
          :cast_id="cast_data.cast_id"
          :shop_site_id="setting.common_shop_site_id"
          :image_list="castImageCommon"
        ></image-selector>
      </v-card-text>
    </v-card>
    <div class="pa-2 indigo">
      <span class="text-subtitle-1 font-weight-bold white--text"
        >媒体個別の設定</span
      >
    </div>
    <v-expansion-panels flat tile>
      <v-expansion-panel class="item-card-backcolor">
        <v-expansion-panel-header class="pa-1 pl-4 pr-4 text-left">
          <span style="text-decoration: underline">使い方を見る</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ul>
            <li>
              媒体別にキャスト画像を設定できます。媒体毎に画像を分けたい場合にご活用いただけます。
            </li>
            <li>画像を設定していない場合は、共通設定の画像で更新されます。</li>
            <li>設定できる画像枚数の上限は媒体毎に異なります。</li>
            <li>
              ボード<v-icon dense>mdi-content-paste</v-icon
              >をクリックすると共通設定から画像をコピーできます。
            </li>
          </ul>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <template v-if="castImageShopSite.length">
      <v-card
        v-for="(shopSite, index) in castImageShopSite"
        :key="shopSite.id"
        tile
        elevation="0"
        class="item-card pa-2 item-card-backcolor"
        style="max-width: 100%"
      >
        <v-card-text class="pa-0 pb-2">
          <div class="pb-2 mb-2">
            <span
              class="text-subtitle-1 font-weight-bold"
              :style="
                castImageShopSite[index].color.length != 0
                  ? 'color:' + castImageShopSite[index].color
                  : ''
              "
              >{{ castImageShopSite[index].name }}</span
            >
          </div>
          <div class="pb-2 mb-2">
            この媒体で使用するキャスト画像を設定します。未設定の場合、共通設定の画像で更新されます。
          </div>
          <image-selector
            ref="refImageShopSiteComponent"
            :img_num="castImageShopSite[index].img_num"
            :cast_id="cast_data.cast_id"
            :shop_site_id="castImageShopSite[index].shop_site_id"
            :image_list="castImageShopSite[index].image_list"
            @copy_click="
              openCopyDialog($event, $refs.refImageShopSiteComponent[index])
            "
          ></image-selector>
        </v-card-text>
      </v-card>
    </template>
    <div v-else>
      <v-card tile elevation="0" class="item-card pa-2">
        利用できる項目はありません。
      </v-card>
    </div>
    <v-card tile elevation="0" class="item-card pa-2">
      <v-card-actions class="item-card">
        <v-container>
          <v-row>
            <v-col cols="6" sm="3">
              <general-button btn_type="info" btn_block @click-event="onSubmit"
                ><template v-slot:icon
                  ><v-icon left>mdi-content-save</v-icon></template
                >保存</general-button
              >
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="3">
              <general-button btn_type="info" btn_block @click-event="onBack"
                ><template v-slot:icon
                  ><v-icon left>mdi-chevron-left</v-icon></template
                >前へ</general-button
              >
            </v-col>
            <v-col cols="3">
              <general-button btn_type="gray" btn_block :btn_disabled="true"
                ><template v-slot:icon
                  >次へ<v-icon right>mdi-chevron-right</v-icon></template
                ></general-button
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
    <!-- 共通設定の画像をコピー -->
    <v-dialog
      v-model="copyCommonImage"
      scrollable
      persistent
      width="100%"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">全媒体共通の画像をコピー</span>
        </v-card-title>
        <v-card-text>
          <v-subheader class="pl-0">
            コピーしたい画像をクリックしてください。
          </v-subheader>
          <div class="d-flex flex-wrap">
            <div v-for="(image, index) in commonImageList" :key="index">
              <div
                v-if="commonImageList[index].path"
                class="ma-1 dialog_select_image"
              >
                <v-img
                  @click="onSubmitDialog(index)"
                  width="80px"
                  height="80px"
                  :src="setting.cdn + commonImageList[index].path"
                >
                </v-img>
              </div>
            </div>
            <div
              v-show="commonImageList.filter((v) => v.path != null).length == 0"
            >
              ※現在、選択できる画像はありません
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="3">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="cancelDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >キャンセル</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilFunc from "@/common/utilFunc.js";
import imageSelector from "./imageSelector.vue";

export default defineComponent({
  components: {
    imageSelector,
  },
  props: ["cast_data", "site_setting"],
  setup(props, ctx) {
    const CastImagesRepository = repositoryFactory.get("castImages");
    const CastsRepository = repositoryFactory.get("casts");

    const refImageCommonComponent = ref();
    const refImageShopSiteComponent = ref();

    const state = reactive({
      // 媒体共通の画像情報を格納する配列
      castImageCommon: [],
      // 媒体個別の設定情報および画像情報を格納する配列
      castImageShopSite: [],
      shopSiteImageList: [],
      // コピーダイアログ関連
      copyCommonImage: false,
      commonImageList: [],
      clickImage: {
        index: null,
        refComponent: null,
      },
      // 読み込み時データ保持(保存前比較用)
      keepData: {
        castImageCommon: [],
        castImageShopSite: [],
      },
    });

    const onSubmit = async () => {
      // 親コンポーネントの保存処理に投げる
      ctx.emit("com_save");
    };

    // チェック処理
    const checkValid = async () => {
      return true;
    };

    const setCastImage = (shop_site_id, imageList) => {
      const resultList = [];
      let sort = 1;
      for (let index = 0; index < imageList.length; index++) {
        if (imageList[index].path) {
          resultList.push({
            cast_id: props.cast_data.cast_id,
            shop_site_id: shop_site_id,
            path: imageList[index].path,
            type: imageList[index].type,
            width: imageList[index].width,
            height: imageList[index].height,
            is_enable: imageList[index].is_enable,
            sort: sort,
          });
          sort++;
        }
      }
      return resultList;
    };

    const compareCheck = () => {
      // 差分があればtrueを返す(順序も含め完全に一致しているかチェック=コンポーネントからリストを取得し比較)
      if (
        !utilFunc.compareArray(
          state.keepData.castImageCommon,
          refImageCommonComponent.value.getImageList()
        )
      )
        return false;
      for (
        let index = 0;
        index < state.keepData.castImageShopSite.length;
        index++
      ) {
        if (
          !utilFunc.compareArray(
            state.keepData.castImageShopSite[index].image_list,
            refImageShopSiteComponent.value[index].getImageList()
          )
        )
          return false;
      }
      return true;
    };

    // 保存処理
    const save = async () => {
      // 読み込み時のデータと比較して変わっていれば保存する。差分なければ何もしない。
      if (compareCheck()) return true;

      // データ保存のため、save_list配列に格納(並び順が変わっている可能性があるため、コンポーネントからリストを取る)
      let save_list = [];
      save_list = save_list.concat(
        setCastImage(
          setting.common_shop_site_id,
          refImageCommonComponent.value.getImageList()
        )
      );
      for (
        let index = 0;
        index < refImageShopSiteComponent.value.length;
        index++
      ) {
        save_list = save_list.concat(
          setCastImage(
            state.castImageShopSite[index].shop_site_id,
            refImageShopSiteComponent.value[index].getImageList()
          )
        );
      }
      // 元のデータを全消しして、新規追加する(save_all)
      const param = {
        shop_id: store.getters["shops/currentShop"].id,
        cast_id: props.cast_data.cast_id,
        cast_images: save_list,
      };
      await CastImagesRepository.save_all(param)
        .then((response) => {
          if (response.data) {
            // データ追加OK
          }
        })
        .catch((error) => {
          throw (
            "ERROR:tabImage.vue/save CastImagesRepository.save_all (" +
            error +
            ")"
          );
        });
      // 1件目の画像を本人キャスト画像にする
      let setImage = "";
      if (
        save_list.length > 0 &&
        props.cast_data.cast_image != save_list[0].path
      ) {
        setImage = save_list[0].path;
      } else {
        setImage = props.cast_data.cast_image;
      }
      const params = {
        id: props.cast_data.cast_id,
        shop_id: store.getters["shops/currentShop"].id,
        image: setImage,
      };
      await CastsRepository.update(params)
        .then((response) => {
          if (response.data) {
            // データ追加OK
          }
        })
        .catch((error) => {
          throw (
            "ERROR:tabImage.vue/save CastsRepository.update (" + error + ")"
          );
        });
      ctx.emit("update_header", { "image": setImage });

      return true;
    };

    const init = async () => {
      // 媒体共通の画像リスト(配列)を生成・・・setting.cast_image_numberに定義された数だけ配列つくっておく
      for (let index = 0; index < setting.cast_image_number; index++) {
        state.castImageCommon.push({
          path: null,
          type: null,
          width: null,
          height: null,
          is_enable: true,
        });
      }

      // 更新媒体で営業タイプの媒体を取得
      const tmpShopSite = store.getters["shop_sites/getShopSiteList"];

      // 媒体個別の画像リスト(配列)を生成・・・定義された数だけ配列つくっておく
      for (let index = 0; index < tmpShopSite.length; index++) {
        if (
          tmpShopSite[index].sites_type == setting.site_type_sales &&
          tmpShopSite[index].is_active
        ) {
          const siteSetting = props.site_setting.find(
            (v) => v.site_id === tmpShopSite[index].site_id
          );
          const tmpList = [];
          for (let index = 0; index < siteSetting.body_limit; index++) {
            tmpList.push({
              path: null,
              type: null,
              width: null,
              height: null,
              is_enable: true,
            });
          }
          state.castImageShopSite.push({
            cast_id: props.cast_data.cast_id,
            shop_site_id: tmpShopSite[index].id,
            img_num: siteSetting.body_limit,
            name: tmpShopSite[index].name,
            color: tmpShopSite[index].color,
            image_list: tmpList,
          });
        }
      }

      // 画像データを取得
      const params = new URLSearchParams();
      params.append("shop_id", store.getters["shops/currentShop"].id);
      params.append("cast_id", props.cast_data.cast_id);
      await CastImagesRepository.list_cast(params)
        .then((response) => {
          if (response.data) {
            // データ追加OK
            // DBから取得した内容を格納(媒体共通)
            let index = 0;
            Object.keys(response.data).forEach(function (key) {
              if (index > state.castImageCommon.length) return;
              if (
                response.data[key].shop_site_id == setting.common_shop_site_id
              ) {
                for (let dbkey in state.castImageCommon[index]) {
                  set(
                    state.castImageCommon[index],
                    dbkey,
                    response.data[key][dbkey]
                  );
                }
                index++;
              }
            });
            // DBから取得した内容を格納(媒体個別)
            for (
              let sindex = 0;
              sindex < state.castImageShopSite.length;
              sindex++
            ) {
              let index = 0;
              Object.keys(response.data).forEach(function (key) {
                if (index > state.castImageCommon.length) return;
                if (
                  response.data[key].shop_site_id ==
                  state.castImageShopSite[sindex].shop_site_id
                ) {
                  for (let dbkey in state.castImageShopSite[sindex].image_list[
                    index
                  ]) {
                    set(
                      state.castImageShopSite[sindex].image_list[index],
                      dbkey,
                      response.data[key][dbkey]
                    );
                  }
                  index++;
                }
              });
            }
            // 読み込み時のデータを保持(保存前に比較するため)
            state.keepData.castImageCommon = JSON.parse(
              JSON.stringify(state.castImageCommon)
            );
            state.keepData.castImageShopSite = JSON.parse(
              JSON.stringify(state.castImageShopSite)
            );
          }
        })
        .catch((error) => {
          throw (
            "ERROR:tabImage.vue/save CastImagesRepository.save_all (" +
            error +
            ")"
          );
        });
    };

    init();

    const openCopyDialog = async ($event, refCom) => {
      state.commonImageList = refImageCommonComponent.value.getImageList();
      // コピーボタンが押された箇所を保持
      state.clickImage.index = $event.target.getAttribute("copy_index");
      state.clickImage.refComponent = refCom;

      state.copyCommonImage = true;
    };

    const cancelDialog = async () => {
      state.clickIndex = null;
      state.copyCommonImage = false;
    };

    const onSubmitDialog = async (index) => {
      // ダイアログで選択された画像を、クリックされた箇所にコピーする
      const newImage = JSON.parse(JSON.stringify(state.commonImageList[index]));
      set(newImage, "is_enable", true);
      state.clickImage.refComponent.setImage(newImage, state.clickImage.index);
      cancelDialog();
    };

    const onBack = () => {
      ctx.emit("tab_back");
    };

    return {
      props,
      setting,
      refImageCommonComponent,
      refImageShopSiteComponent,
      ...toRefs(state),
      onSubmit,
      checkValid,
      save,
      cancelDialog,
      onSubmitDialog,
      openCopyDialog,
      onBack,
    };
  },
});
</script>

<style scoped>
.dialog_select_image {
  border: 2px solid white;
}
.dialog_select_image:hover {
  border: 2px solid #1976d2;
  cursor: pointer;
}
</style>
